<template>
  <div class="deliveryEncasementDetailsPage">
    <HomePageTop/>
    <div class="deliveryEncasementDetailsTable">
      <header class="flexAndCenter">
        <div>{{ $fanyi("配送单号") }}：{{ this.$route.query.id }}</div>
        <div @click="exportExcel">
          {{ $fanyi("导出装箱明细") }}
        </div>
      </header>
      <section>
        <div class="deliveryBoxDetailsContainer" v-for="(item,index) in porderBoxDetailTable" :key="index">
          <div class="sendMode">{{ $fanyi("发送方式") }}：{{ item.logistics_name }}</div>
          <div class="order">
            <div class="header flex">
              <div class="boxNumber">{{ $fanyi("箱号") }}</div>
              <div class="cartonSize">{{ $fanyi("箱规(cm)") }}</div>
              <div class="actualWeight">{{ $fanyi("实际重量(kg)") }}</div>
              <div class="orderNumber" style="width: 202px">{{ $fanyi("订单号") }}</div>
              <div class="boxNumber">{{ $fanyi("番号") }}</div>
              <div class="actualWeight" style="width: 95px">{{ $fanyi("照片") }}</div>
              <div class="goodsSpecification">{{ $fanyi("商品属性") }}</div>
              <div class="boxNumber">{{ $fanyi("装箱数") }}</div>
              <div class="price">{{ $fanyi("单价(元)") }}</div>
              <div class="price">{{ $fanyi("小计(元)") }}</div>
              <div class="tagNumber">{{ $fanyi("标签编号") }}</div>
              <div class="pOrderRemark" style="border-right:none;width: 162.5px;">{{ $fanyi("配送单单番备注") }}</div>
            </div>
            <div class="goodsBox">
              <div v-for="(boxItem,boxIndex) in item.box_data" :key="boxItem.box_number" class="goodsConUl">
                <div class="flexAndCenter" style="border-bottom: 1px solid #DFDFDF">
                  <div class="boxNumber">{{ boxItem.box_number }}</div>
                  <div class="cartonSize">{{ boxItem.box_size }}</div>
                  <div class="actualWeight">{{ boxItem.weight }}</div>
                </div>
                <div>
                  <div class="flex" v-for="freightItem in boxItem.freight_detail"
                       :key="freightItem.order_sn">
                    <div class="orderNumber" style="border-bottom: 2px solid #F6F6F6;border-right: none;height: auto">
                      {{ freightItem.order_sn }}
                    </div>
                    <div>
                      <div v-for="(orderItem,orderIndex) in freightItem.order_detail" :key="orderItem.sorting"
                           class="flexAndCenter orderDetailContainer"
                           style="border-left: 1px solid #F6F6F6;"
                           :style="orderIndex===freightItem.order_detail.length-1?'':'border-bottom: 1px solid #F6F6F6;'">
                        <div class="boxNumber flexAndCenterAndCenter height80px">{{ orderItem.sorting }}</div>
                        <div class="actualWeight flexAndCenterAndCenter height80px" style="width: 95px">
                          <el-popover placement="right" trigger="hover">
                            <img :src="orderItem.pic"
                                 style="width: 300px; height: 300px"
                            />
                            <img :src="orderItem.pic"
                                 style="width: 60px;height: 60px"
                                 slot="reference"
                            />
                          </el-popover>
                        </div>
                        <div class="goodsSpecification">
                          <div class="showDetail flexAndCenterAndCenter">
                            <el-popover trigger="hover" placement="top">
                              <div class="showDetailCon u-line">
                                <p v-for="(bitem, bindex) in orderItem.detail"
                                   :key="bitem.key + bindex"
                                   :title="bitem.key + ':' + bitem.value"
                                   class="goodsDetailOpt ispay">
                                  {{ $fun.trunUpperCase(bitem.key) }}:{{ bitem.value }}
                                </p>
                              </div>
                              <div class="showDetailCon  u-line" slot="reference">
                                <p v-for="(bitem, bindex) in orderItem.detail"
                                   :key="bitem.key + bindex"
                                   :title="bitem.key + ':' + bitem.value"
                                   class="goodsDetailOpt u-line ispay">
                                  {{ $fun.trunUpperCase(bitem.key) }}:{{ bitem.value }}
                                </p>
                              </div>
                            </el-popover>
                          </div>
                        </div>
                        <div class="boxNumber height80px flexAndCenterAndCenter">{{ orderItem.in_box_num }}</div>
                        <div class="price">{{ Number(orderItem.confirm_price).toFixed(2) }}</div>
                        <div class="price">{{ Number(orderItem.sum_price).toFixed(2) }}</div>
                        <div class="tagNumber flexAndCenterAndCenter">
                          <el-popover trigger="hover" placement="top">
                            <div>
                              <div v-for="(tagItem,tagIndex) in orderItem.porder_detail_tag" :key="tagIndex">
                                <div>{{ tagItem.type }}</div>
                                <div>{{ tagItem.no }}</div>
                                <div>{{ tagItem.goods_no }}</div>
                              </div>
                            </div>
                            <div class="tagListContainer" slot="reference">
                              <div v-for="(tagItem,tagIndex) in orderItem.porder_detail_tag" :key="tagIndex"
                                   class="tagContainer">
                                <div class="u-line">{{ tagItem.type }}</div>
                                <div class="u-line">{{ tagItem.no }}</div>
                                <div class="u-line">{{ tagItem.goods_no }}</div>
                              </div>
                            </div>
                          </el-popover>
                        </div>
                        <div class="pOrderRemark" style="border-right:none">
                          <div class="u-line-3" :title="orderItem.porder_detail_client_remark">
                            {{ orderItem.porder_detail_client_remark }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import HomePageTop from "../../../../../../components/head/HomePageTop.vue";
import websiteUrl from "@/api/wangZhi";

export default {
  data() {
    return {
      porderBoxDetailTable: [],
    }
  },
  components: {
    HomePageTop,
  },
  created() {
    this.$api.porderBoxDetail({
      porder_sn: this.$route.query.id,
    }).then((res) => {
      this.porderBoxDetailTable = res.data.logistics_data;
    });
  },
  methods: {
    //导出装箱明细
    exportExcel() {
      this.loading = this.$loading({
        lock: true,
        text: this.$fanyi("数据导出中"),
        spinner: "el-icon-loading fontSize50",
        background: "rgba(255, 255, 255, 0.7)",
      });
      var xhr = new XMLHttpRequest();
      let url = websiteUrl + '/client/dataDownload.porderBoxDetailExcel';
      xhr.open("post", url, true);
      xhr.responseType = "blob";
      xhr.setRequestHeader('accept', '*/*');
      xhr.setRequestHeader('accept-language', 'zh-CN');
      xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
      let user_token = localStorage.getItem('japan_user_token')
      xhr.setRequestHeader("ClientToken", 'Bearer ' + user_token);
      let that = this;
      xhr.onload = function () {
        if (this.status == 200 && this.response.type != "text/html") {
          if (window.navigator.msSaveOrOpenBlob) { //IE浏览器
            navigator.msSaveBlob(this.response, `配送单号${this.$route.query.id}装箱明细`);
          } else {//非IE浏览器
            var link = document.createElement("a");
            link.href = window.URL.createObjectURL(this.response);
            link.download = `配送单号${that.$route.query.id}装箱明细`;
            link.click();
            window.URL.revokeObjectURL(link.href);
            that.loading.close();
            that.$forceUpdate();
          }
        } else {
          console.log("响应出错啦!");
        }
      };
      xhr.send(`porder_sn=${this.$route.query.id}`);
    },
  }
}
</script>

<style lang="scss" scoped>
.deliveryEncasementDetailsPage {
  .deliveryEncasementDetailsTable {
    width: 1400px;
    margin: 30px auto 0;
    background: #FFFFFF;
    border-radius: 10px;
  }

  header {
    justify-content: space-between;
    height: 73px;
    padding: 0 26px;
    border-bottom: 1px solid #F6F6F6;

    div:first-child {
      font-weight: bold;
      font-size: 20px;
    }

    div:last-child {
      width: 130px;
      height: 34px;
      background: #B4272B;
      border-radius: 8px;
      color: #fff;
      cursor: pointer;
      text-align: center;
      line-height: 34px;
      font-size: 14px;
    }
  }

  section {
    padding: 0 27px 26px;

    .deliveryBoxDetailsContainer {
      font-weight: bold;

      .sendMode {
        font-size: 14px;
        font-weight: bold;
        padding: 25px 0 19px;
      }

      .order {
        border: 1px solid #DFDFDF;

        .boxNumber {
          width: 60px;
        }

        .cartonSize {
          width: 120px;
        }

        .actualWeight {
          width: 116px;
        }

        .orderNumber {
          width: 200px;
        }

        .goodsSpecification {
          width: 150px;

          .showDetail {
            height: 62px;
            overflow: hidden;

            .showDetailCon {
              width: 130px;
              line-height: 20px;

              .goodsDetailOpt {
                width: 130px;
                text-align: left;
              }
            }
          }
        }

        .price {
          width: 80px;
        }

        .tagNumber {
          width: 160px;

          .tagListContainer {
            width: 137px;
            height: 47px;
            overflow: hidden;
            line-height: 16px;

            .tagContainer {
              cursor: pointer;

              div {
                width: 137px;
                text-align: left;
              }
            }
          }
        }

        .pOrderRemark {
          width: 163px;
        }

        .header {
          height: 56px;
          background: #F0F0F0;
          z-index: 5;
          position: sticky;
          top: 58px;

          div {
            line-height: 56px;
            text-align: center;
            font-size: 14px;
            color: #333;
            border-right: 1px solid #DFDFDF;
            box-sizing: border-box;
          }
        }

        .goodsConUl {
          color: #333;
          font-size: 14px;
          display: -webkit-box;

          .boxNumber, .cartonSize, .actualWeight, .orderNumber, {
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            border-right: 1px solid #DFDFDF;
            box-sizing: border-box;
          }

          .height80px {
            height: 80px;
          }

          .orderDetailContainer {
            border-bottom: 1px solid #DFDFDF;

            .goodsSpecification, .price, .tagNumber, .pOrderRemark {
              display: flex;
              justify-content: center;
              align-items: center;
              border-right: 1px solid #DFDFDF;
              box-sizing: border-box;
              height: 80px;
            }
          }
        }
      }
    }
  }
}
</style>
